<template>
    <div class="table-style__row">
        <div class="table-style__th">{{ label }} <span v-if="isRequired" style="color:#ff0000;">*</span></div>
        <div class="table-style__td">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: { type: String, default: "" },
        isRequired: { type: Boolean, default: false },
    },
};
</script>

<style></style>
