var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v(_vm._s(_vm.label) + " "), _vm.isRequired ? _c('span', {
    staticStyle: {
      "color": "#ff0000"
    }
  }, [_vm._v("*")]) : _vm._e()]), _c('div', {
    staticClass: "table-style__td"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }